
import DefaultCardLayout from "@/components/Card/DefaultCardLayout.vue";
import SidebarProfile from "@/components/Nav/Sidebar/SidebarProfile.vue";

export default {
	name: "Notifications",
	components: {SidebarProfile, DefaultCardLayout},
	prop: {},
	setup() {
		return {};
	}
}
