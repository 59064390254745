
import DefaultCardLayout from "@/components/Card/DefaultCardLayout.vue";
import DefaultButton from "@/components/Button/DefaultButton.vue";
import BtnClose from "@/components/Nav/BtnClose.vue";
import SidebarProfile from "@/components/Nav/Sidebar/SidebarProfile.vue";

export default {
	name: "uploadBoostpack",
	components: {SidebarProfile, BtnClose, DefaultButton, DefaultCardLayout},
	props: {
		percentIndicator: {
			type: String,
			default: '%',
		},
		percentValue: {
			type: Number,
			default: 100,
		},
		itemsCount: {
			type: Number,
			default: 2,
		},
		itemsCountUploaded: {
			type: Number,
			default: 1,
		},
	},
	setup() {
		return {};
	}

}
