
import {computed, defineComponent, ref} from 'vue';
import {useVModel} from "@/composable/input/useVModel";
import {ExclamationCircleIcon} from '@heroicons/vue/solid'


export default defineComponent({
	name: 'InputPassword',
	components: {ExclamationCircleIcon},
	emits: ['update:password'],
	props: {
		// password: String,
		password: {
			type: String,
			default: ""
		},
		inputType: {
			type: String,
			default: "password"
		},
		inputPlaceholder: {
			type: String,
			default: "Password"
		},
		requiredField: {
			type: Boolean,
			default: true
		},
		showPasswordStrengthoMeter: {
			type: Boolean,
			default: true
		},
		errorMessage: {
			type: String,
			default: ""
		},
	},
	setup(props) {

		const currentPassword = computed(() => props.password);
		const showPassword = ref(false);
		const passwordScore = ref(0);

		return {
			passwordState: useVModel(props, 'password'),
			currentPassword,
			showPassword,
			passwordScore,
			// passwordScore: 0,
			// password: '',
			// chars: {
			// 	lower: 'abcdefghijklmnopqrstuvwxyz',
			// 	upper: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
			// 	numeric: '0123456789',
			// 	symbols: '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'
			// },
			// charsLength: 12,
			// checkStrength: function() {
			// 	if(!currentPassword) return passwordScore.value = 0;
			// 	passwordScore.value = zxcvbn(this.password).score + 1;
			// },
		};
	},
})
