import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "profile m-4 md:grid md:grid-cols-4 md:grid-flow-col  2xl:grid-cols-8" }
const _hoisted_2 = {
  class: "md:col-span-1 md:grid-cols-1",
  style: {"display":"unset"}
}
const _hoisted_3 = { class: "text-white md:col-span-3 md:grid-cols-3 md:max-w-2xl justify-self-center lg:justify-self-stretch md:ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar_profile = _resolveComponent("sidebar-profile")
  const _component_Shared = _resolveComponent("Shared")
  const _component_Subscribed = _resolveComponent("Subscribed")
  const _component_settings = _resolveComponent("settings")
  const _component_Notifications = _resolveComponent("Notifications")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_sidebar_profile)
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_Shared),
      _createVNode(_component_Subscribed),
      _createVNode(_component_settings),
      _createVNode(_component_Notifications)
    ])
  ]))
}