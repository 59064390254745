import {PaginationMetaInterface} from "@/services/boostpack/core/pagination_meta.interface";
import {OrderList} from "@/services/boostpack/order/order_list.type";
import {BoostpackTask} from "@/services/boostpack/task/boostpack_task.type";

export class BoostpackTaskList extends Array<BoostpackTask> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<BoostpackTask> = []) {
		super();
		this.push(...items);
		Object.setPrototypeOf(this, OrderList.prototype);
	}

	add(items: BoostpackTask) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): BoostpackTask {
		return this[index];
	}

	getItemById(BoostpackTaskId: number): BoostpackTask | undefined {
		return this.find( ({ id }) => id === BoostpackTaskId);
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}