

import BtnClose from "@/components/Nav/BtnClose.vue";
import CreditCard from "@/components/Payment/CreditCard.vue";
import DefaultButton from "@/components/Button/DefaultButton.vue";
import InputField from "@/components/Input/InputField.vue";
import DefaultCardLayout from "@/components/Card/DefaultCardLayout.vue";
import SidebarProfile from "@/components/Nav/Sidebar/SidebarProfile.vue";
import DeleteProfileDialog from "@/components/Modal/DeleteProfileDialog.vue";

export default {
	name: "Settings",
	components: {
		DeleteProfileDialog,
		SidebarProfile,
		BtnClose,
		CreditCard,
		DefaultButton,
		InputField,
		DefaultCardLayout
	},
	props: {
		invoiceDate: {
			type: Date,
			default: 'dd.mm.yy',
		},
	},
	setup() {
		return {};
	}
}
