
import {defineComponent, reactive} from 'vue';
import InputNickMail from "@/components/Input/InputNickMail.vue";
import InputPassword from "@/components/Input/InputPassword.vue";
import {AuthService} from "@/services/api/auth/auth.service";
import useUserRepository from "@/composable/user/useUserRepository";
import GoogleLoginComponent from "@/components/Auth/Socialite/GoogleLoginComponent.vue";
import DefaultButton from "@/components/Button/DefaultButton.vue";
import router from '@/router';
import DefaultCardLayout from "@/components/Card/DefaultCardLayout.vue";
import useTranslation from "@/composable/translation/useTranslation";
import DangerAlert from "@/components/Alert/Base/DangerAlert.vue";
import useAuthRepository from "@/composable/auth/useAuthRepository";
import Switch from "@/components/FilterSort/Switch.vue";
import i18n from "@/i18n";

export default defineComponent({
	name: 'RegisterComponent',
	components: {
    Switch,
		DefaultCardLayout,
		DefaultButton,
		GoogleLoginComponent,
		InputNickMail,
		InputPassword,
		DangerAlert,
	},
	props: {},
	setup() {
		const {initUserByUserType} = useUserRepository();
		const {
			errorMessage,
			errorTitle,
			clearErrorMessage,
			setErrorTitle,
			setErrorMessage,
			getDeviceHash
		} = useAuthRepository();

		const user = reactive({
			email: "",
			emailError: "",
			password: "",
			passwordError: "",
			terms: false,
		});

		const {t,} = useTranslation();

		function clearUserErrors() {
			user.emailError = "";
			user.passwordError = "";
		}

		async function onSubmit() {
			clearErrorMessage();
			clearUserErrors();

			/* eslint-disable @typescript-eslint/camelcase */
			const registerData = {
				name: user.email,
				email: user.email,
				password: user.password,
				password_confirmation: user.password,
				terms: user.terms,
				device_name: getDeviceHash(),
			};

			try {
				const token = await AuthService.register(registerData);
				if (token) {
					const userResponse = await AuthService.getUser();
					if (userResponse && userResponse.id) {
						initUserByUserType(userResponse);
						router.push('/');
					} else {
						setErrorTitle(t('auth.auth_error_failed_title'));
						setErrorMessage(t('auth.failed'));
					}
				}
			} catch (error) {
				console.log(error);
				setErrorTitle(t('auth.auth_error_failed_title'));
				setErrorMessage(t('auth.failed'));
				if (error.message.email) {
					user.emailError = error.message.email.join("<br>");
				}
				if (error.message.password) {
					user.passwordError = error.message.password.join("<br>");
				}
			}
		}

		return {
      tabs: [
        {name: i18n.global.t("auth.LoginComponent.loginTitle"), link: '/login', current: false},
        {name: i18n.global.t("auth.RegisterComponent.registerTitle"), link: '/register', current: true}
      ],
			t, user,
			onSubmit,
			errorMessage,
			errorTitle,
			clearErrorMessage,
		}

	},
})
