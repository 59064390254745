
import {defineComponent, ref} from 'vue';
import {Vugel} from "vugel";
import DefaultIcon from "@/components/Icon/DefaultIcon.vue";

export default defineComponent({
	name: 'BoostpackCanvas',
	components: {
    DefaultIcon
	},
	props: {
		textAreaStyle: {
			type: String,
			default: ''
		},
		AuthorStyle: {
			type: String,
			default: ''
		},
		AboutStyle: {
			type: String,
			default: ''
		},
		active: {
			type: String,
			default: 'block'
		},
    canvasWidth: {
			type: String,
			default: 'w-auto'
		},
    canvasRadius: {
			type: String,
			default: 'rounded-3xl'
		},
    width: {
			type: String,
			default: 'w-full'
		},
		height: {
			type: String,
      default: 'h-48'
		},
		customCss: {
			type: String,
      default: 'h-48'
		},
	},
	setup() {
		//IMAGE
		const canvasImage = ref();
    //TEXT
    const title = ref("Hello World");
    const description = ref("");
    const fontSize = ref("48px");
    const fontFamily = ref("serif");

    // function convertToImage() {
    // 	window.open(imageCanvas.value.toDataURL('png'));
    // }

    // onMounted(() => {
    // })

    return {Vugel, canvasImage, title, description, fontSize, fontFamily};
  }
});
