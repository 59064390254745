
import Filter from "@/components/FilterSort/Filter.vue";
import Search from "@/components/Search/Search.vue";
import DefaultBoostpackCardLayout from "@/components/Card/DefaultBoostpackCardLayout.vue";
import SidebarProfile from "@/components/Nav/Sidebar/SidebarProfile.vue";

export default {
	name: "Subscribed",
	components: {
		SidebarProfile,
		DefaultBoostpackCardLayout, Search,
		Filter
	},
	props: {
		boostpackName: {
			type: String,
			default: 'Boostpack :Name'
		},
	},
	setup() {
		return {};
	}
}
