import ApiService from "../base/api.service";
import {OrderFactory} from "@/services/boostpack/order/order_factory.type";
import {Order} from "@/services/boostpack/order/order.type";

const resource = '/user/order';

/* eslint-disable */
class ResponseError extends Error {
	errorCode: any;
	errorMessage: any;
	constructor(errorCode: any, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		this.errorCode = errorCode;
	}
}

const OrderService = {
	get() {
		try {
			//TODO pagination interface
			return ApiService.get(`${resource}`);
		} catch (error) {
			throw new ResponseError(
				error.status,
				error.message
			);
		}
	},

	getOrders: async function (page: string|null = null) {
		try {
			let result = {};
			let url = resource;
			if (page) {
				url += '?page=' + page;
			}
			const response = await ApiService.get(url);
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const orderFactory = new OrderFactory();
					result = orderFactory.createOrderListByResponse(responseData);
				}
			}
			return result;
		} catch (error) {
			if (error.response) {
				throw new ResponseError(
					error.response.status,
					error.response.error.message
				);
			} else if (error.request) {
				// The request was made but no response was received
				// Error details are stored in error.reqeust
				console.log(error.request);
			} else {
				// Some other errors
				console.log('Error', error.message);
			}
		}
	},

	getOrderBySearch: async function (search: string, page: string|null = null, perPage: number|null = null) {
		try {
			let result: any = {};
			const params = new URLSearchParams([['search', search]]);
			if (page) {
				params.append('page', page);
			}
			if (perPage) {
				params.append('perPage', String(perPage));
			}
			const response = await ApiService.get(`${resource}/search`, {params});
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const orderFactory = new OrderFactory();
					result = orderFactory.createOrderListWithMetaData(response.data);
					// result = boostpackFactory.createBoostpackListByResponse(responseData);
				}
			}
			return result;
		} catch (error) {
			if (error.response) {
				throw new ResponseError(
					error.response.status,
					error.response.error.message
				);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
		}
	},

	async getOrder(id: number):  Promise<Order | null>
	{
		try {
			const response = await ApiService.get(`${resource}/${id}`);
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const orderFactory = new OrderFactory();
					return orderFactory.createOrderByResponse(responseData);
				}
			}
			return null;
		} catch (error) {
			throw new ResponseError(
				error.status,
				error.message
			);
		}
	},
}

export { OrderService, ResponseError };