import {OrderItemInterface} from "@/services/boostpack/order/item/order_item.interface";
import {BoostpackTaskList} from "@/services/boostpack/task/boostpack_task_list.type";

export class OrderTask implements OrderItemInterface {

	id: number;
	parentId?: number|null;
	orderId: number;
	boostpackId: number;
	type: string;
	state: string;
	tasks?: BoostpackTaskList|null;
	reference?: string|null;
	netAmount?: number|null;
	totalAmount?: number|null;
	taxAmount?: number|null;
	quantity?: number|null;
	grandTotal?: number|null;
	exchangeRate?: number|null;
	createdAt: Date;
	updatedAt: Date;
	data?: any;


	constructor(id: number, uuid: string, orderId: number,  boostpackId: number, type: string, state: string, tasks: BoostpackTaskList|undefined, createdAt: Date, updatedAt: Date, reference: string|null = null, transaction: string|null = null, parentId: number|null = null, data: any = null) {
		this.id = id;
		this.parentId = parentId;
		this.type = type;
		this.state = state;
		this.tasks = tasks;
		this.orderId = orderId;
		this.boostpackId = boostpackId;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.reference = reference;
		this.parentId = parentId;
		this.data = data;
	}
}