<template>

  <swiper
      :effect="'coverflow'"
      :grabCursor="true"
      :centeredSlides="true"
      :slidesPerView="'auto'"
      :thumbs="{ swiper: thumbsSwiper }"
      :coverflowEffect="{
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: false,
    }"
      :pagination="false"
      :modules="modules"
      class="mySwiper2"
  >
    <swiper-slide v-for="item in items" :key="item.index">
      <div class="bg-gray-100 p-2 rounded-3xl">
        <div class="flex mb-2 justify-center p-2 mx-0 space-x-4 object-center text-center rounded-3xl bg-gray-100">
          <DefaultIcon icon-Color="text-gray-500" icon="image_icon" ></DefaultIcon>
          <DefaultIcon icon-Color="text-gray-500" icon="textposition_icon"  :key="item.index" v-on:click="fontSize++"></DefaultIcon>
          <DefaultIcon icon-Color="text-gray-500" icon="textcolour_icon"  v-on:click="fontSize--"></DefaultIcon>
          <DefaultIcon icon-Color="text-gray-500" icon="textfont_icon" ></DefaultIcon>
          <DefaultIcon icon-Color="text-gray-500" icon="backgroundcolour_icon" ></DefaultIcon>
          <DefaultIcon icon-Color="text-gray-500" icon="text_icon" ></DefaultIcon>
        </div>
      </div>

      <div class="pt-0 -mr-6 md:-mr-4" @click="removeItem(index)" >
        <btn-close></btn-close>
      </div>
      <boostpack-canvas
          v-bind:text-area-style="{ fontSize: fontSize + 'px' }"
          v-bind:author-style="{ fontSize: fontSize + 'px' }"
          v-bind:about-style="{ fontSize: fontSize + 'px' }"
      ></boostpack-canvas>
    </swiper-slide>
     <swiper-slide>
       <div class="bg-gray-100 p-2 rounded-3xl">
         <div class="flex mb-2 justify-center p-2 mx-0 space-x-4 object-center text-center rounded-3xl bg-gray-100">
       <DefaultIcon icon-Color="text-gray-200" icon="image_icon" ></DefaultIcon>
       <DefaultIcon icon-Color="text-gray-200" icon="textposition_icon"></DefaultIcon>
       <DefaultIcon icon-Color="text-gray-200" icon="textcolour_icon"></DefaultIcon>
       <DefaultIcon icon-Color="text-gray-200" icon="textfont_icon" ></DefaultIcon>
       <DefaultIcon icon-Color="text-gray-200" icon="backgroundcolour_icon" ></DefaultIcon>
       <DefaultIcon icon-Color="text-gray-200" icon="text_icon" ></DefaultIcon>
         </div>
       </div>

       <div class="bg-gray-100 dark:bg-gray-600 rounded-3xl border-2 border-gray-300" @click="addItem">
      <div class="py-7 sm:py-20 px-5">
        <p class="text-sm text-center dark:text-gray-400 text-bp-dark">
          {{ $t("profile.createBoostpack1.createStyleDetail") }}</p>
        <p class="text-6xl text-center dark:text-gray-400 font-bold text-bp-dark p-2">
          {{ $t("profile.createBoostpack1.createStyleAddItem") }}</p>
        <p class="text-md text-center dark:text-gray-400 font-bold text-bp-dark">
          {{ $t("profile.createBoostpack1.createStyleAddItemText") }}</p>
        <p class="text-sm text-center dark:text-gray-400 text-bp-dark">
          {{ $t("profile.createBoostpack1.createStyleAddItemDetail") }}</p>
      </div>
    </div>
     </swiper-slide>
  </swiper>
  <swiper class="mySwiper bg-gray-100" @swiper="setThumbsSwiper" :spaceBetween="0" :slidesPerView="4" :freeMode="true" :watchSlidesVisibility="true" :watchSlidesProgress="true"
  >
    <swiper-slide class="m-0 bg-gray-100" v-for="item in items" :key="item.index">
      <boostpack-canvas class=" rounded-md" canvas-width="w-12 h-12" canvas-radius="" active="hidden"></boostpack-canvas>
    </swiper-slide>
     <swiper-slide class="m-0 bg-gray-100">
    <div class="bg-gray-100  dark:bg-gray-600 rounded-md  border-2 border-gray-300 w-12 h-12" @click="addItem">
        <p class="text-4xl text-center dark:text-gray-400 font-bold text-bp-dark">
          {{ $t("profile.createBoostpack1.createStyleAddItem") }}</p>
    </div>
     </swiper-slide>
  </swiper>
</template>
<script>
import {defineComponent, ref} from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import BoostpackCanvas from "@/components/Boostpack/Canvas/BoostpackCanvas";

// import Swiper core and required modules
import SwiperCore, {
  EffectCoverflow, Pagination, Thumbs
} from 'swiper';
import BtnClose from "@/components/Nav/BtnClose";
import DefaultIcon from "@/components/Icon/DefaultIcon";

SwiperCore.use([EffectCoverflow, Pagination, Thumbs]);

export default defineComponent({
  name: "PreviewSwiper",
  components: {
    DefaultIcon,
    BtnClose,
    Swiper,
    SwiperSlide,
    BoostpackCanvas
  },
  props: {
    TextAreaStyle: {
      type: String,
      default: ''
    },
    AuthorStyle: {
      type: String,
      default: ''
    },
    AboutStyle: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      thumbsSwiper: null,
      fontSize: 20,
    };
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
  },
  setup() {
    const items=ref([])
    const addItem = () =>
        items.value.push([{
          "code":"",
          "name":"",
          "category":"",
          "quantity":"",
        }]);
    console.log(items.value);
    const removeItem = (index) => {
      items.value.splice(index, 1)
    }
    return {
      items,
      removeItem,
      addItem,
      modules: [EffectCoverflow, Pagination, Thumbs],
    };
  },
})
</script>

<style>
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  /*display: -webkit-box;*/
  /*display: -ms-flexbox;*/
  /*display: -webkit-flex;*/
  /*display: flex;*/
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  display: block;
  max-width: 320px!important;
  height: 100%;
  object-fit: cover;
}

.swiper-container {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 0px 0;
  margin-top: 12px;
  margin-bottom: 12px;
}

.mySwiper .swiper-slide {
  margin-right: 0!important;
  margin: 0!important;
  max-width: 120px!important;
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper2 .swiper-slide {
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.mySwiper2 .swiper-slide-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}



/*.mySwiper {*/
/*  height: 25%;*/
/*  box-sizing: border-box;*/
/*  padding: 10px 0;*/
/*}*/

/*.mySwiper .swiper-slide {*/
/*  height: 25%;*/
/*  opacity: 0.4;*/
/*}*/

/*.mySwiper .swiper-slide-thumb-active {*/
/*  height: 25%;*/
/*  opacity: 1;*/
/*}*/

/*.mySwiper2 {*/
/*  width: 100%;*/
/*}*/

/*.mySwiper2 .swiper-slide {*/
/*  background-position: center;*/
/*  background-size: cover;*/
/*  width: 300px;*/
/*  height: 400px;*/
/*}*/

/*.mySwiper2 .swiper-slide img {*/
/*  display: block;*/
/*  width: 100%;*/
/*}*/
</style>
