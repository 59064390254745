import {OrderInterface} from "@/services/boostpack/order/order.interface";
import {OrderItemList} from "@/services/boostpack/order/item/order_item_list.type";
import {OrderTaskList} from "@/services/boostpack/order/task/order_task_list.type";

export class Order implements OrderInterface {

	id: number;
	uuid: string;
	parentId?: number|null;
	userId: number;
	userAddressId: number;
	orderItems?: OrderItemList;
	orderTasks?: OrderTaskList;
	type: string;
	state: string;
	language: string;
	taxCode: string;
	countryCode: string;
	currencyCode: string;
	orderNumber: string;
	transaction?: string|null;
	reference?: string|null;
	netAmount?: number|null;
	totalAmount?: number|null;
	taxAmount?: number|null;
	itemQuantity?: number|null;
	grandTotal?: number|null;
	exchangeRate?: number|null;
	createdAt: Date;
	updatedAt: Date;
	data?: any;

	constructor(id: number, uuid: string, userId: number,  userAddressId: number, type: string, state: string, orderNumber: string, taxCode: string, countryCode: string, currencyCode: string, language: string, createdAt: Date, updatedAt: Date, reference: string|null = null, orderItems: OrderItemList|undefined, orderTasks: OrderTaskList|undefined, transaction: string|null = null, parentId: number|null = null, data: any = null) {
		this.id = id;
		this.uuid = uuid;
		this.parentId = parentId;
		this.userId = userId;
		this.userAddressId = userAddressId;
		// userId: number;
		this.type = type;
		this.state = state;
		this.language = language;
		this.taxCode = taxCode;
		this.countryCode = countryCode;
		this.currencyCode = currencyCode;
		this.orderNumber = orderNumber;
		this.orderItems = orderItems;
		this.orderTasks = orderTasks;
		// user: UserInterface;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.reference = reference;
		this.transaction = transaction;
		this.parentId = parentId;
		this.data = data;
	}

	public getNextNotificationDate(): Date|any
	{
		const currentDate = new Date();
		const orderTaskTemp = this.orderTasks;
		let nextDate: Date|null = null;
		const nextOrderTask = orderTaskTemp?.find((orderTask) =>
		{
			return orderTask.tasks?.find((task) => {
				if (task.executionDate) {
					nextDate = task.executionDate;
					return task.executionDate.getTime() >= currentDate.getTime();
				}
			});
		});
		return nextDate;
	}
}