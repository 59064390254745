
import DefaultIcon from "@/components/Icon/DefaultIcon.vue";
import SidebarItem from "@/components/Nav/Sidebar/SidebarItem.vue";
import {defineComponent, ref} from 'vue';
import HeaderItem from "@/components/Nav/Header/HeaderItem.vue";

export default defineComponent({
	name: 'SidebarProfile',
	components: {HeaderItem, DefaultIcon, SidebarItem},
	props: {},
	emits: [],
	setup() {
		const showMenu = ref(false);

		function toggleSidebar() {
			showMenu.value = !showMenu.value;
		}

		function open() {
			showMenu.value = true;
		}

		function close() {
			showMenu.value = false;
		}

		return {
			showMenu, toggleSidebar, open, close
		}

	},
	methods: {}
})


