
import {defineComponent, ref} from 'vue';
import DefaultCardLayout from "@/components/Card/DefaultCardLayout.vue";
import DefaultButton from "@/components/Button/DefaultButton.vue";
import BtnClose from "@/components/Nav/BtnClose.vue";
import SidebarProfile from "@/components/Nav/Sidebar/SidebarProfile.vue";
import PreviewSwiper from "@/components/Swiper/PreviewSwiper.vue";
import CheckoutNavBar from "@/components/Boostpack/Subscribe/Checkout/CheckoutNavBar.vue";
import {
  COMPLETED_STEP,
  CURRENT_STEP,
  UPCOMING_STEP
} from "@/components/Boostpack/Subscribe/BoostpackSubscribeCheckout.vue";
import useTranslation from "@/composable/translation/useTranslation";
import InputField from "@/components/Input/InputField.vue";
// import TextUploadEditDialog from "@/components/Modal/TextUploadEditDialog.vue";
// import ImageUploadDialog from "@/components/Modal/ImageUploadDialog.vue";

export default defineComponent({
	name: "createBoostpack1",
	components: {
    InputField,
    CheckoutNavBar,
		SidebarProfile,
		BtnClose,
		DefaultButton,
    PreviewSwiper,
		DefaultCardLayout
	},
	setup() {
    const showHeader = ref(true);
    const {t} = useTranslation();
    const activeStep = ref(1);
    const steps = ref([
      {
        id: 1,
        name: t('modal.SubscribeDialog.step1'),
        href: '#',
        status: CURRENT_STEP,
        allowedToGoBack: true,
        allowedToGoForward: true,
        allowedToCloseBoostpack: true,
        allowedToShowButton: false,
      },
      {
        id: 2,
        name: t('profile.createBoostpack2.createTitle'),
        href: '#',
        status: UPCOMING_STEP,
        allowedToGoBack: true,
        allowedToGoForward: true,
        allowedToCloseBoostpack: true,
        allowedToShowButton: false,
      },
    ]);

    function getSelectedStep() {
      return steps.value.find(o => o.id == activeStep.value);
    }

    function isAllowedToShowButton() {
      let result = false;
      const selectedStep = getSelectedStep();
      if (selectedStep) {
        result = selectedStep.allowedToShowButton;
      }
      return result;
    }

    function isAllowedToGoBack() {
      let result = false;
      const selectedStep = getSelectedStep();
      if (selectedStep) {
        result = selectedStep.allowedToGoBack;
      }
      return result;
    }

    function isAllowedToCloseBoostpack() {
      let result = false;
      const selectedStep = getSelectedStep();
      if (selectedStep) {
        result = selectedStep.allowedToCloseBoostpack;
      }
      return result;
    }

    function changeStep(step) {
      if (step) {
        const oldStep = steps.value.find( ({ id }) => id === activeStep.value);
        if (oldStep) {
          if ( oldStep.id < step) {
            oldStep.status = COMPLETED_STEP;
          } else {
            oldStep.status = UPCOMING_STEP;
          }
        }

        const newStep = steps.value.find( ({ id }) => id === step);
        if (newStep && (!oldStep || step > oldStep.id) ) {
          newStep.status = CURRENT_STEP;
        }

        activeStep.value = step;
      }
    }

    function goToNextStep() {
      if (activeStep.value <= 2) {
        changeStep(activeStep.value +1);
      }
    }



    return {
      showHeader,
      steps,
      activeStep,
      isAllowedToCloseBoostpack,
      isAllowedToShowButton,
      isAllowedToGoBack,
      changeStep,
      goToNextStep
    };
	}
})


