import {ref} from 'vue';

export default function useAuthRepository() {
	const errorMessage = ref("");
	const errorTitle = ref("");

	const deviceHash = ref("");

	function getDeviceHash(maxLength = 254) {
		deviceHash.value = window.navigator.userAgent.substr(0,maxLength);
		return deviceHash.value;
	}

	function clearErrorMessage() {
		errorTitle.value = "";
		errorMessage.value = "";
	}

	function setErrorTitle(title: string) {
		errorTitle.value = title;
	}

	function setErrorMessage(message: string) {
		errorMessage.value = message;
	}

	return {
		errorMessage,
		errorTitle,
		clearErrorMessage,
		setErrorTitle,
		setErrorMessage,
		getDeviceHash,
	}
}
