import {BoostpackTaskInterface} from "@/services/boostpack/task/boostpack_task.interface";
import {UserInterface} from "@/services/boostpack/user/user.interface";

export class BoostpackTask implements BoostpackTaskInterface {
	id: number;
	uuid: string;
	state: string;
	data?: any;
	userChannelId: number;
	boostpackItemId: number;
	boostpackOrderTaskId: number;
	userId?: number;
	user?: UserInterface;
	tries?: number;
	executionDate: Date;
	executedAt: Date;
	createdAt: Date;
	updatedAt: Date;


	constructor(id: number, uuid: string, state: string, userChannelId: number, boostpackItemId: number,  boostpackOrderTaskId: number, userId: number, executionDate: Date, executedAt: Date, createdAt: Date, updatedAt: Date, tries: number|undefined, data: any = null, user: UserInterface|undefined,) {
		this.id = id;
		this.uuid = uuid;
		this.state = state;
		this.userChannelId = userChannelId;
		this.boostpackItemId = boostpackItemId;
		this.boostpackOrderTaskId = boostpackOrderTaskId;
		this.userId = userId;
		this.user = user;
		this.tries = tries;
		this.data = data;
		this.executionDate = executionDate;
		this.executedAt = executedAt;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
	}
}