/**
 *
 */
import {OrderList} from "@/services/boostpack/order/order_list.type";
import {Order} from "@/services/boostpack/order/order.type";
import {OrderTaskList} from "@/services/boostpack/order/task/order_task_list.type";
import {BoostpackTaskFactory} from "@/services/boostpack/task/boostpack_task_factory.type";
import {BoostpackTaskList} from "@/services/boostpack/task/boostpack_task_list.type";
import {OrderTaskFactory} from "@/services/boostpack/order/task/order_task_factory.type";

export class OrderFactory {

	/* eslint-disable */

	/**
	 *
	 * @param orderData
	 */
	createOrderListByResponse(orderData: Record<string, any>): OrderList{
		const orderList = new OrderList();

		for (const [key, item] of Object.entries(orderData)) {
			orderList.push(this.createOrderByResponse(item));
		}

		return orderList;
	}

	createOrderListWithMetaData(orderResponse: Record<string, any>): OrderList {
		const orderData = orderResponse.data;
		const metaData = orderResponse.meta;
		let orderList = this.createOrderListByResponse(orderData);
		orderList.enhanceMetaData(metaData);

		return orderList;
	}

	createOrderTasks(orderTasks: any): undefined|OrderTaskList {
		const orderTaskFactory = new OrderTaskFactory();
		return orderTaskFactory.createOrderTaskListByResponse(orderTasks);
	}

	/**
	 *
	 * @param item
	 */
	createOrderByResponse(item: Record<string, any>): Order{
		const id = item.id;
		const uuid = item.uuid;
		const userId = item.userId;
		const userAddressId = item.user_address_id;
		const orderNumber = item.order_number;
		const type = item.type;
		const state = item.state;
		const language = item.language;
		const orderItems = item.orderItems;
		const orderTasks = this.createOrderTasks(item.orderTasks);
		const taxCode = item.tax_code;
		const countryCode = item.country_code;
		const currencyCode = item.currency_code;
		const createdAt = item.created_at;
		const updatedAt = item.updated_at;
		const reference = item.reference;
		const transaction = item.transaction;
		const parent_id = item.parent_id;
		const data = item.data;
		return new Order(id, uuid, userId, userAddressId, type, state, orderNumber, taxCode, countryCode, currencyCode, language, createdAt, updatedAt, reference, orderItems, orderTasks, transaction, parent_id, data);
	}
}