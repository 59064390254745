
import {defineComponent} from 'vue';
import BtnClose from "@/components/Nav/BtnClose.vue";

export default defineComponent({
	name: 'CreditCard',
	components: {BtnClose},
	props: {
		// creditCard: {} as () => CreditCard,
	},
	setup() {
		return {};
	},
	data() {
		return {
			CreditCards: [
				{
					id: 1,
					url: 'https://i.imgur.com/kGkSg1v.png',
					name: 'Karthik P',
					cardNumber: '4642 3489 9867 7632',
					valid: '11/15',
					expire: '03/25',
					csv: '...'
				},
				{
					id: 2,
					url: 'https://i.imgur.com/Zi6v09P.png',
					name: 'Erwin L',
					cardNumber: '1262 3489 9999 7632',
					valid: '11/31',
					expire: '03/25',
					csv: '...'
				},
			]
		}
		// setup(props) {
		// https://v3.vuejs.org/guide/composition-api-introduction.html#standalone-computed-properties
		// using `toRefs` to create a Reactive Reference to the `user` property of props
		// const { creditCard } = toRefs(props)

		// const repositories = ref([])
		// const getUserRepositories = async () => {
		// 	// update `props.user` to `user.value` to access the Reference value
		// 	repositories.value = await fetchUserRepositories(user.value)
		// }
		//
		// onMounted(getUserRepositories)
		//
		// // set a watcher on the Reactive Reference to user prop
		// watch(user, getUserRepositories)
		//
		// const searchQuery = ref('')
		// const repositoriesMatchingSearchQuery = computed(() => {
		// 	return repositories.value.filter(
		// 		repository => repository.name.includes(searchQuery.value)
		// 	)
		// })

		// return {
		// 	creditCard
		// }
		// }
	}
})
