/**
 *
 */
import {BoostpackTaskList} from "@/services/boostpack/task/boostpack_task_list.type";
import {BoostpackTask} from "@/services/boostpack/task/boostpack_task.type";

export class BoostpackTaskFactory {

	/* eslint-disable */

	/**
	 *
	 * @param orderData
	 */
	createBoostpackTaskListByResponse(orderData: Record<string, any>): BoostpackTaskList {
		const boostpackTaskList = new BoostpackTaskList();

		for (const [key, item] of Object.entries(orderData)) {
			boostpackTaskList.push(this.createBoostpackTaskByResponse(item));
		}

		return boostpackTaskList;
	}

	createBoostpackTaskListWithMetaData(boostpackTaskResponse: Record<string, any>): BoostpackTaskList {
		const boostpackTaskData = boostpackTaskResponse.data;
		const metaData = boostpackTaskResponse.meta;
		let boostpackTaskList = this.createBoostpackTaskListByResponse(boostpackTaskData);
		boostpackTaskList.enhanceMetaData(metaData);

		return boostpackTaskList;
	}

	/**
	 *
	 * @param item
	 */
	createBoostpackTaskByResponse(item: Record<string, any>): BoostpackTask {
		const id = item.id;
		const uuid = item.uuid;
		const userId = item.userId;
		const userChannelId = item.user_channel_id;
		const boostpackItemId = item.boostpack_item_id;
		const boostpackOrderTaskId = item.boostpack_order_task_id;
		const state = item.state;
		const tries = item.tries;
		const executedAt = new Date(item.executed_at);
		const executionDate = new Date(item.execution_date);
		const data = item.data;
		const createdAt = new Date(item.created_at);
		const updatedAt = new Date(item.updated_at);

		return new BoostpackTask(id, uuid, state, userChannelId, boostpackItemId, boostpackOrderTaskId, userId, executionDate, executedAt, createdAt, updatedAt, tries, data, undefined);
	}
}