
import Search from "@/components/Search/Search.vue";
import Filter from "@/components/FilterSort/Filter.vue";
import DefaultBoostpackCardLayout from "@/components/Card/DefaultBoostpackCardLayout.vue";
import DefaultCardLayout from "@/components/Card/DefaultCardLayout.vue";
import SidebarProfile from "@/components/Nav/Sidebar/SidebarProfile.vue";

export default {
	name: "Shared",
	components: {
		SidebarProfile, DefaultBoostpackCardLayout, Filter, Search, DefaultCardLayout
	},
	props: {},
	setup() {
		return {};
	}

}
