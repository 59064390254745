
import SidebarProfile from "@/components/Nav/Sidebar/SidebarProfile.vue";
import Settings from "@/components/Profile/Settings.vue";
import Notifications from "@/components/Profile/Notifications.vue";
import Subscribed from "@/components/Profile/Subscribed.vue";
import Shared from "@/components/Profile/Shared.vue";

export default {
	name: 'Profile',
	components: {
		Shared,
		Subscribed,
		Notifications,
		Settings,
		SidebarProfile,
	},
	props: {},
}
