
import {defineComponent, ref, toRef} from 'vue';
import {nanoid} from 'nanoid'
import DefaultButton from "@/components/Button/DefaultButton.vue";
import BtnClose from "@/components/Nav/BtnClose.vue";
import DefaultCardLayout from "@/components/Card/DefaultCardLayout.vue";

export default defineComponent({
	name: 'SubscribeEditDialog',
	components: {DefaultCardLayout, BtnClose, DefaultButton},
	props: {
		id: {type: String, default: nanoid()},
		title: {type: String},
		description: {type: String},
		visible: {type: Boolean, default: false},
		showButton: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['confirm', 'cancelConfirm', 'showSubscribeAd'],
	setup(props, {emit}) {
		const dialogTitle = toRef(props, 'title');
		const dialogDescription = toRef(props, 'description');
		const visible = toRef(props, 'visible');
		const dialogVisible = ref(visible.value);
		const showAd = ref(visible.value);

		function toggleDialog() {
			dialogVisible.value = !dialogVisible.value;
		}

		function showSubscribeAd() {
			showAd.value = !showAd.value;
		}

		function hideDialog() {
			dialogVisible.value = false;
		}

		const cancelDialog = () => {
			dialogVisible.value = false;
			emit('cancelConfirm');
		};

		const confirm = () => {
			dialogVisible.value = false;
			emit('confirm');
		};

		return {
			dialogTitle,
			dialogDescription,
			dialogVisible,
			showAd,
			toggleDialog,
			showSubscribeAd,
			hideDialog,
			confirm,
			cancelDialog
		}
	},
})
