/**
 *
 */
import {BoostpackTaskList} from "@/services/boostpack/task/boostpack_task_list.type";
import {BoostpackTask} from "@/services/boostpack/task/boostpack_task.type";
import {OrderTaskList} from "@/services/boostpack/order/task/order_task_list.type";
import {OrderTask} from "@/services/boostpack/order/task/order_task.type";
import {BoostpackTaskFactory} from "@/services/boostpack/task/boostpack_task_factory.type";

export class OrderTaskFactory {

	/* eslint-disable */

	/**
	 *
	 * @param orderData
	 */
	createOrderTaskListByResponse(orderData: Record<string, any>): OrderTaskList{
		const orderTaskList = new OrderTaskList();

		for (const [key, item] of Object.entries(orderData)) {
			orderTaskList.push(this.createOrderTaskTaskByResponse(item));
		}

		return orderTaskList;
	}

	createOrderTaskListWithMetaData(orderTaskResponse: Record<string, any>): OrderTaskList {
		const orderTaskData = orderTaskResponse.data;
		const metaData = orderTaskResponse.meta;
		let orderTaskList = this.createOrderTaskListByResponse(orderTaskData);
		orderTaskList.enhanceMetaData(metaData);

		return orderTaskList;
	}

	/**
	 *
	 * @param item
	 */
	createOrderTaskTaskByResponse(item: Record<string, any>): OrderTask{
		const boostpackTaskFactory = new BoostpackTaskFactory();

		const id = item.id;
		const uuid = item.uuid;
		const parentId = item.parent_id;
		const orderId = item.order_id;
		const boostpackId = item.boostpack_id;
		const type = item.type;
		const state = item.state;
		const tasks = boostpackTaskFactory.createBoostpackTaskListByResponse(item.tasks);
		const reference = item.reference;
		const transaction = item.transaction;
		const netAmount = item.net_amount;
		const taxAmount = item.tax_amount;
		const totalAmount = item.total_amount;
		const taxCode = item.tax_code;
		const quantity = item.quantity;
		const grandTotal = item.grand_total;
		const exchangeRate = item.exchange_rate;
		const data = item.data;
		const createdAt = item.created_at;
		const updatedAt = item.updated_at;

		return new OrderTask(id, uuid, orderId, boostpackId, type, state, tasks, createdAt, updatedAt, reference, transaction, parentId, data);
	}
}