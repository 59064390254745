
import {onMounted} from 'vue'
import {AuthService} from "@/services/api/auth/auth.service";
import router from "@/router";

export default {
	components: {},
	props: {},
	setup() {
		function logout() {
			try {
				AuthService.signOut();
			} catch (e) {
				console.error(e);
			} finally {
				router.push('/');
			}
		}

		onMounted(() => {
			logout();
		})

		return {}
	},
}
