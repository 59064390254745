
import {onMounted, ref} from 'vue'
import {AuthService} from "@/services/api/auth/auth.service";
import useUserRepository from "@/composable/user/useUserRepository";
import router from '@/router';
import {createToast} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
	name: 'GoogleLogin',
	props: {
		provider: {
			type: String,
			default: "google"
		}
	},
	setup(props: any) {
		const providerLink = ref("");

		const {initUserByUserType} = useUserRepository();

		onMounted(() => {
			providerLink.value = process.env.VUE_APP_API_STABLE_URL + '/login/' + props.provider + "?originUrl=" + window.location.href;
		})

		async function openpopup(providerLink: string) {
			const percent = 75;
			let w = 630, h = 440, left = 0, top = 0; // default sizes
			if (window.screen) {
				w = window.screen.availWidth * percent / 100;
				h = window.screen.availHeight * percent / 100;
				left = (window.screen.width / 2) - (w / 2);
				top = (window.screen.height / 2) - (h / 2);
			}
			let googleLoginData: any = null;
			console.log("provider_link", providerLink);
			const pop = window.open(providerLink, '_blank', 'width=' + w + ',height=' + h + ' top=' + top + ', left=' + left + ',location=yes,clearsessioncache=yes,clearcache=yes');

			const messageEventListener = async function (event: any) {
				if (event.origin === process.env.VUE_APP_API_DOMAIN) {
					googleLoginData = JSON.parse(event.data);
					try {
						const signinResponse = await AuthService.signInSocialite(googleLoginData.token);
						if (signinResponse) {
							const userResponse = await AuthService.getUser();
							if (userResponse && userResponse.id) {
								initUserByUserType(userResponse);
							}
						}
					} catch (error) {
						console.log("ERROR Login via Google!");
						//TODO create own component
						createToast({
								title: 'Error',
								description: 'Google cannot login!'
							},
							{
								type: 'danger',
								showIcon: true,
								timeout: 8000,
								position: 'top-right',
								transition: 'slide',
							})
					}

					window.removeEventListener("message", messageEventListener, false);

					pop && pop.close();

					router.push('/');
				}

				return;
			};

			window.addEventListener("message", messageEventListener, false);

			return false;
		}

		return {providerLink, openpopup}
	},
}
