
import {defineComponent, reactive, onMounted, ref} from 'vue';
import InputNickMail from "@/components/Input/InputNickMail.vue";
import {AuthService} from "@/services/api/auth/auth.service";
import DefaultButton from "@/components/Button/DefaultButton.vue";
import DefaultCardLayout from "@/components/Card/DefaultCardLayout.vue";
// import BtnClose from "@/components/Nav/BtnClose.vue";
import useAlerts from "@/composable/alert/useAlerts";
import FullScreenSpinner from "@/components/Loading/FullScreenSpinner.vue";

export default defineComponent({
	name: 'ForgotPasswordComponent',
	components: {
		FullScreenSpinner,
		// BtnClose,
		DefaultCardLayout,
		DefaultButton,
		InputNickMail,
	},
	props: {},
	setup() {
		const {addSuccess, addError} = useAlerts();
		const isSending = ref(false);

		const user = reactive({
			email: "",
			password: "",
		});

		async function onSubmit() {
			isSending.value = true;
			const response = await AuthService.sendResetPasswordLink(user.email);
			if (response) {
				const success = {
					id: "",
					title: "SUCCESS",
					message: "Password link successfully send",
					active: true,
				}
				addSuccess(success);
			} else {
				const error = {
					id: "",
					title: "Error",
					message: "Error sending reset link!",
					active: true,
				}
				addError(error);
			}
			isSending.value = false;
		}

		onMounted(() => {
			//
		})

		return {
			user,
			onSubmit,
			isSending,
		}

	},
})
