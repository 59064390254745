import {PaginationMetaInterface} from "@/services/boostpack/core/pagination_meta.interface";
import {Order} from "@/services/boostpack/order/order.type";

export class OrderList extends Array<Order> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<Order> = []) {
		super();
		this.push(...items);
		Object.setPrototypeOf(this, OrderList.prototype);
	}

	add(items: Order) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): Order {
		return this[index];
	}

	getItemById(orderId: number): Order | undefined {
		return this.find( ({ id }) => id === orderId);
	}

	getItemByUuid(orderUuid: string): Order | undefined {
		return this.find( ({ uuid }) => uuid === orderUuid);
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}