
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {useRoute} from "vue-router";
import InputNickMail from "@/components/Input/InputNickMail.vue";
import {AuthService} from "@/services/api/auth/auth.service";
import DefaultButton from "@/components/Button/DefaultButton.vue";
import router from '@/router';
import DefaultCardLayout from "@/components/Card/DefaultCardLayout.vue";
import InputPassword from "@/components/Input/InputPassword.vue";
import useAlerts from "@/composable/alert/useAlerts";

export default defineComponent({
  name: 'ResetPasswordComponent',
  components: {
    InputPassword,
    DefaultCardLayout,
    DefaultButton,
    InputNickMail,
  },
  props: {},
  setup() {
	  const {addSuccess, addError} = useAlerts();

	  const isLoading = ref(true);
	  const resetToken = ref();
	  const hasValidToken = ref(false);

	  /* eslint-disable @typescript-eslint/camelcase */
	  const user = reactive({
		  email: "",
		  password: "",
		  password_confirmation: "",
	  });

	  async function sendResetPasswordLink() {
		  //TODO disable button
		  const response = await AuthService.sendResetPasswordLink(user.email);
		  console.log(response);
		  if (response) {
			  const success = {
				  id: "",
				  title: "SUCCESS",
				  message: "Password link successfully send",
				  active: true,
			  }
			  addSuccess(success);
		  } else {
			  const error = {
				  id: "",
				  title: "Error",
				  message: "Error sending reset link!",
				  active: true,
			  }
			  addError(error);
		  }
	  }

	  async function resetPassword() {
		  const resetData = {
			  email: user.email,
			  password: user.password,
			  password_confirmation: user.password_confirmation,
			  token: resetToken.value,
		  };
		  const response = await AuthService.resetPassword(resetData);
		  if (response) {
			  const success = {
				  id: "",
				  title: "SUCCESS",
				  message: "Password successfully reset",
				  active: true,
			  }
			  addSuccess(success);
			  router.push('/login');
		  } else {
			  const error = {
				  id: "",
				  title: "Error",
				  message: "Error resetting the password",
				  active: true,
			  }
			  addError(error);
		  }
	  }

	  async function checkToken() {
		  const resetData = {
			  email: user.email,
			  token: resetToken.value,
		  };
		  return await AuthService.hasValidResetToken(resetData);
	  }


	  onMounted(() => {
		  console.log(useRoute().params);
		  resetToken.value = useRoute().params.token.toString();
		  user.email = useRoute().params.email.toString();
		  //TODO
		  console.log(resetToken.value, "-", user.email);

		  if (resetToken.value.length > 0 && user.email.length > 0) {
			  checkToken().then(response => {
				  hasValidToken.value = response != false;
			  });
		  }

		  // verifyPasswordResetHash(passwordResetHash).then(response =>)
		  //
		  // if (pageReloaded.value && boostpack.value && boostpack.value.id) {
		  //   removeBoostpack(boostpack.value.id);
		  //   setPageReloaded(false);
		  //   if (boostpackId.value) {
		  // 	  loadDetailBoostpackById(boostpackId.value);
		  //   } else if (boostpackReference.value) {
		  // 	  loadDetailBoostpackByReference(boostpackReference.value);
		  //   }
		  // } else {
		  //   if (boostpack.value) {
		  // 	  initBoostpack();
		  //   } else {
		  // 	  if (boostpackId.value) {
		  // 		  loadDetailBoostpackById(boostpackId.value);
			// 	  } else if (boostpackReference.value) {
			// 		  loadDetailBoostpackByReference(boostpackReference.value);
		  //
			// 	  }
			//   }
		  // }
		  isLoading.value = false;
	  })

    return {
		user,
		sendResetPasswordLink,
		resetPassword,
		hasValidToken,
		resetToken
	}

  },
})
