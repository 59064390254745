
import {defineComponent, onBeforeUnmount, onMounted, onUnmounted, ref} from 'vue';
import List, {ListItemInterface} from "@/components/Core/List.vue";
import {OrderList} from "@/services/boostpack/order/order_list.type";
import {OrderService} from "@/services/api/order/order.service";
import SidebarProfile from "@/components/Nav/Sidebar/SidebarProfile.vue";
import {OrderItem} from "@/services/boostpack/order/item/order_item.type";
import {PaginationInterface} from "@/types/common/pagination.interface";

export default defineComponent({
	name: 'OrderList',
	components: {
		SidebarProfile,
		List
	},
	props: {},
	setup() {
		const orders: any|OrderList|Array<OrderItem> = ref();
		const orderPagination: PaginationInterface|any = ref();
		const orderListData: Array<ListItemInterface | any[]> | any = ref([]);
		const orderLoading = ref(false);
		const isFetchingData = ref(false);
		const currentPage = ref(1);

		function startLoading() {
			orderLoading.value = true;
		}

		function stopLoading() {
			orderLoading.value = false;
			isFetchingData.value = false;
		}

		function convertOrdersToOrderDataList(clearList = false)
		{
			if (clearList) {
				orderListData.value = [];
			}
			orders.value.forEach((order) => {
				const orderDetail = order.orderItems
					.map((item) => {
						return item.boostpack.title;
					}).join("\n");
				let nextNotificationDate = order.getNextNotificationDate();
				if (nextNotificationDate) {
					nextNotificationDate = nextNotificationDate.toLocaleString();
				} else {
					nextNotificationDate = 'Unbekannt!';
				}
				const listItem: ListItemInterface = {
					id: order.uuid,
					title: order.orderNumber,
					info: order.orderNumber,
					detail: orderDetail,
					additionalInfo: 'Nächste Notification <time>' + nextNotificationDate + "</time>",
					items: order.orderItems,
					// labels: Array<{ key: string; value: string; color?: string }>,
					link: 'profile/order/' + order.uuid,
					// type?: string;
					date: order.createDate,
					//eslint-disable-next-line
					// icon?: any | undefined,
					// data?: any | undefined;
				};
				orderListData.value.push(listItem);
			});
			//todo foreach orders.value create orderlist with text and info and labels
		}

		async function loadData(page) {
			if (isFetchingData.value) {
				return;
			}
			const perPage: number | null = 4;
			let orderLength = 0;
			if (orders.value && orders.value.length) {
				orderLength = orders.value.length;
			}
			if (page >= 1 && ((page * perPage) <= orderLength)) {
				stopLoading();
				return false;
			}
			OrderService.getOrderBySearch('', page, perPage).then((orderList) => {
				isFetchingData.value = true;
				orderPagination.value = orderList.paginationMetaData;
				orders.value = orderList;
				convertOrdersToOrderDataList(true);
				stopLoading();
			});
		}

		onBeforeUnmount(() => {
			startLoading();
			setTimeout(function () {
				stopLoading();
			}, 3000);
		})

		onUnmounted(() => {
			stopLoading();
		})

		onMounted(() => {
			startLoading();
			loadData(1);
			setTimeout(function () {
				stopLoading();
			}, 3000);
		})

		return {
			loadData,
			orderListData,
			orders,
			orderPagination
		}
	},
})
