
import {defineComponent, reactive} from 'vue';
import InputNickMail from "@/components/Input/InputNickMail.vue";
import InputPassword from "@/components/Input/InputPassword.vue";
import {AuthService} from "@/services/api/auth/auth.service";
import useUserRepository from "@/composable/user/useUserRepository";
import GoogleLoginComponent from "@/components/Auth/Socialite/GoogleLoginComponent.vue";
import DefaultButton from "@/components/Button/DefaultButton.vue";
import LinkButton from "@/components/Button/LinkButton.vue";
import DefaultLabel from "@/components/Label/DefaultLabel.vue";
import router from '@/router';
import DefaultCardLayout from "@/components/Card/DefaultCardLayout.vue";
// import DangerAlert from "@/components/Alert/Base/DangerAlert.vue";
import useAuthRepository from "@/composable/auth/useAuthRepository";
import useTranslation from "@/composable/translation/useTranslation";
import Switch from "@/components/FilterSort/Switch.vue";
import i18n from "@/i18n";
import useAlerts from "@/composable/alert/useAlerts";

export default defineComponent({
	name: 'LoginComponent',
	components: {
		Switch,
		// DangerAlert,
		DefaultCardLayout,
		// DefaultLabel,
		LinkButton,
		DefaultButton,
		GoogleLoginComponent,
		InputNickMail,
		InputPassword,
	},
	props: {},
	setup() {
		const {addSuccess, addError, clearErrorAlert} = useAlerts();
		const {initUserByUserType} = useUserRepository();
		const {
			errorMessage,
			errorTitle,
			clearErrorMessage,
			setErrorTitle,
			setErrorMessage,
			getDeviceHash
		} = useAuthRepository();

		const user = reactive({
			email: "",
			password: "",
			emailError: "",
			passwordError: "",
		});

		const {t} = useTranslation();

		async function onSubmit() {
			/* eslint-disable @typescript-eslint/camelcase */
			const loginData = {
				email: user.email,
				password: user.password,
				device_name: getDeviceHash()
			};
			try {
				console.log(loginData)
				clearErrorAlert();
				const token = await AuthService.signIn(loginData);
				if (token) {
					const userResponse = await AuthService.getUser();
					console.log(userResponse)
					if (userResponse && userResponse.id) {
						initUserByUserType(userResponse);
						//TODO show info and hide message
						// addSuccess({title: t('auth.auth_error_failed_title'), message: t('auth.failed'), active: true});
						router.push('/');
						clearErrorAlert();
					}
				}
			} catch (e) {
				console.log(e);
				addError({title: t('auth.auth_error_failed_title'), message: t('auth.failed'), active: true});
				// setErrorTitle(t('auth.auth_error_failed_title'));
				// setErrorMessage(t('auth.failed'));
			}
		}

		return {
			tabs: [
				{name: i18n.global.t("auth.LoginComponent.loginTitle"), link: '/login', current: true},
				{name: i18n.global.t("auth.RegisterComponent.registerTitle"), link: '/register', current: false}
			],
			user,
			onSubmit,
			errorMessage,
			errorTitle,
			clearErrorMessage
		}

	},
})
