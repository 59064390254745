
import {defineComponent, onMounted, onUnmounted, ref} from 'vue';
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/vue/solid'
import {PaginationInterface} from "@/types/common/pagination.interface";
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'
import {DotsVerticalIcon} from '@heroicons/vue/outline'
import {CheckCircleIcon} from '@heroicons/vue/solid'

export interface ListItemInterface {
	id: string;
	title: string;
	detail: string;
	additionalInfo?: string;
	info?: string;
	labels?: Array<{ key: string; value: string; color?: string }>;
	link: string;
	items?: any;
	type?: string;
	date?: string;
	//eslint-disable-next-line
	icon?: any | undefined,
	data?: any | undefined;
}


export default defineComponent({
	name: 'List',
	components: {
		ChevronLeftIcon,
		ChevronRightIcon,
		Menu, MenuButton, MenuItem, MenuItems,
		DotsVerticalIcon,
		CheckCircleIcon
	},
	props: {
		listData: {
			type: Array as () => Array<ListItemInterface>,
			default: () => [],
		},
		paginationData: {},
	},
	emits: ['paginatePage'],
	setup(props, {emit}) {

		function paginatePage(page: number) {
			emit('paginatePage', page);
		}

		function clickItem(item: ListItemInterface) {
			//TODO
		}

		onUnmounted(() => {
			//
		})

		onMounted(() => {
			//
		})

		return {paginatePage}
	},
})
